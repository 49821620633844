import React, { useRef } from 'react';
import {
  useAfterLoad,
  useMembersGeometryToRender,
  useTreeGeometryToRender,
  useTreeMembersToRender,
} from '../hooks/tree.hooks';
import { Box } from '@material-ui/core';
import Member from './Member';
import { MemberId } from '../interfaces';
import { useScrollToRootAfterFirstLoad } from '../hooks/scroll.hooks';
// import { useFetchFamilyExtended } from '../hooks/family.hooks';

export default function Tree({ createdAt, draggableRef }) {
  const memberIds = useTreeMembersToRender();
  const membersGeometry = useMembersGeometryToRender();
  // useFetchFamilyExtended(!!createdAt);
  useAfterLoad(createdAt);
  useScrollToRootAfterFirstLoad(createdAt, draggableRef);

  return (
    createdAt && (
      <Box
        sx={{
          display: 'inline-block',
          position: 'relative',
          padding: '16px 32px',
          touchAction: 'none',
          pointerEvents: 'none',
          minWidth: '100%',
          boxSizing: 'border-box',
          textAlign: 'center',
        }}
      >
        {memberIds.map((id: MemberId) => (
          <Member id={id} geometry={membersGeometry[id]} key={id} />
        ))}
      </Box>
    )
  );
}
