import { request, post } from './http';
import { AccessTokens } from '../interfaces/AccessTokens.interface';
import { AccessCredentials } from '../interfaces/AccessCredentials.interface';

export const authApi = {
  async refreshAccessToken(data: {
    refreshToken: string;
  }): Promise<AccessTokens> {
    return request<AccessTokens>(
      'auth/refresh-access-token',
      { ...data, method: 'POST' },
      true,
    );
  },

  async login(data: AccessCredentials): Promise<AccessTokens> {
    return post<AccessTokens>('auth/login', data);
  },
};
