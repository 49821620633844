import React from 'react';
import { Box, Container, Paper, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import Layout from '../components/Layout';
import LoginForm from '../forms/Login.form';
import { useUnauthenticatedOnly } from '../hooks/auth.hooks';

export function LoginPage(props: RouteComponentProps) {
  useUnauthenticatedOnly();

  return (
    <Layout>
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: 'calc(100vh - 64px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            sx={{
              paddingTop: { xs: 4, md: 8 },
              paddingBottom: { xs: 4, md: 8 },
              paddingLeft: { xs: 3, md: 6 },
              paddingRight: { xs: 3, md: 6 },
            }}
          >
            <Typography variant="h5" component="h1">
              Enter the Makar.family cabinet
            </Typography>
            <LoginForm />
          </Paper>
        </Box>
      </Container>
    </Layout>
  );
}
