import React from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import { useTreeZoomer } from '../hooks/zoom.hooks';

export function Zoomer() {
  const [increment, decrement, reset] = useTreeZoomer();

  return (
    <Box
      sx={{
        position: 'fixed',
        right: 32,
        bottom: 32,
      }}
    >
      <ButtonGroup
        orientation="vertical"
        color="primary"
        variant="contained"
        size="small"
        aria-label="Zoom tree"
      >
        <Button onClick={increment}>
          <AddIcon />
        </Button>
        <Button onClick={reset}>
          <CheckIcon />
        </Button>
        <Button onClick={decrement}>
          <RemoveIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
}
