import React from 'react';
import { navigate } from '@reach/router';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from './Link';
import { Box, Button, ListItemButton } from '@material-ui/core';
import { useIsAuthenticated, useLogout } from '../hooks/auth.hooks';
import { addNotification } from '../store';
import { NotificationType } from '../enums';
// import useIsAuthenticated from '../../hooks/useIsAuthenticated';
// import useLogout from '../../hooks/useLogout';
// import { addError } from '../../store/actions/app';

function Menu({ onClose }: { onClose: () => void }) {
  const isAuthenticated = useIsAuthenticated();
  const logout = useLogout();
  //
  const onLogoutClick = async () => {
    onClose();
    logout();
    await navigate('/');
  };
  // const isAuthenticated = false;
  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <List>
        {isAuthenticated ? (
          <>
            <ListItem>
              <ListItemButton component={Link} to="/update/tree">
                <ListItemText primary="Редагувати дерево" />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={onLogoutClick}>
              <ListItemButton>Вийти</ListItemButton>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemButton component={Link} to="/">
                <ListItemText primary="Головна" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component={Link} to="/login">
                <ListItemText primary="Ввійти" />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );
}

export default Menu;
