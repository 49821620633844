import { get, post } from './http';
import { Family, Matrimony, Member } from '../interfaces';

interface PublicGetResponse extends Family {
  members: Member[];
  matrimonies: Matrimony[];
}

interface PublicGetExtendedResponse {
  members: Member[];
  // matrimonies: Matrimony[];
}

export const familyAPI = {
  async publicGet(alias: string): Promise<PublicGetResponse> {
    return get(`public/families/${alias}`).then(
      ({ members = [], matrimonies, ...data }) =>
        ({
          ...data,
          members: members.map(({ _id, ...m }) => ({ ...m, id: _id })),
          matrimonies: matrimonies.map(({ _id, ...m }) => ({ ...m, id: _id })),
        } as PublicGetResponse),
    );
  },
  async publicGetExtended(alias: string): Promise<PublicGetExtendedResponse> {
    return get(`public/families/${alias}/extended`).then(
      ({ members }) =>
        ({
          members: members.map(({ _id, ...m }) => ({ ...m, id: _id })),
        } as PublicGetExtendedResponse),
    );
  },
};
