import React, { useRef } from 'react';
import { useTreeGeometryToRender } from '../hooks/tree.hooks';
import { usePaint } from '../hooks/relations.hooks';

function RelationsCanvasComponent() {
  const canvas = useRef(null);
  const treeGeo = useTreeGeometryToRender();

  usePaint(canvas);

  return (
    <canvas
      ref={canvas}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        touchAction: 'none',
      }}
      width={treeGeo ? treeGeo.right - treeGeo.left : 0}
      height={treeGeo ? treeGeo.bottom - treeGeo.top : 0}
    />
  );
}

export const RelationsCanvas = React.memo(RelationsCanvasComponent);
