import React, { useRef } from 'react';
import { Box } from '@material-ui/core';
import { useFetchFamily } from '../hooks/family.hooks';
import { useBuildTreeVirtual, useTreeTimestamp } from '../hooks/tree.hooks';
import TreeVirtual from './TreeVirtual';
import Tree from './Tree';
import { DragContainer } from './DragContainer';
import { RelationsCanvas } from './RelationsCanvas';
import { ZoomContainer } from './ZoomContainer';
import { Zoomer } from './Zoomer';
import { TreeControls } from './TreeControls';
import { DraggableContainer } from './DraggableContainer';
import { useDragging } from '../hooks/scroll.hooks';

function TreeContainer() {
  const draggableRef = useRef(null);
  const buildVirtualTree = useBuildTreeVirtual();
  const treeCreatedAt = useTreeTimestamp();

  useFetchFamily();

  const bindDragging = useDragging(draggableRef);

  return (
    <Box
      sx={{
        width: '100vw',
        height: 'calc(100vh - 64px)',

        maxWidth: '100vw',
        maxHeight: 'calc(100vh - 64px)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {buildVirtualTree && <TreeVirtual />}

      <DragContainer bindDragging={bindDragging}>
        <ZoomContainer>
          <DraggableContainer ref={draggableRef}>
            <RelationsCanvas />
            <Tree createdAt={treeCreatedAt} draggableRef={draggableRef} />
          </DraggableContainer>
        </ZoomContainer>
      </DragContainer>
      <TreeControls />
      <Zoomer />
    </Box>
  );
}

TreeContainer.defaultProps = {
  noZoom: false,
};

export default TreeContainer;
