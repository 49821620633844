import React from 'react';
import { useTreeZoom } from '../hooks/zoom.hooks';
import { Box } from '@material-ui/core';

export function ZoomContainer({ children }) {
  const zoom = useTreeZoom();

  return (
    <Box
      sx={{
        transform: `scale(${zoom})`,
        transformOrigin: 'top center',
        pointerEvents: 'none',
        touchAction: 'none',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </Box>
  );
}
