import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Family } from '../../interfaces';
import { familyAPI } from '../../api';
import { RootState } from '../store';
const initialState = {
  id: null,
  title: null,
  alias: null,
  rootMember: null,
} as Family;

export const fetchFamily = createAsyncThunk(
  'family/fetchFamily',
  async (alias: string, thunkAPI) => familyAPI.publicGet(alias),
);

export const loadFamily = createAsyncThunk(
  'family/loadFamily',
  async (alias: string, { dispatch, getState, rejectWithValue }) => {
    const state = <RootState>getState();
    if (alias !== state.family.alias) {
      dispatch(fetchFamily(alias));
      return rejectWithValue('Fetching new family data.');
    }
    return true;
  },
);

export const familySlice = createSlice({
  name: 'family',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFamily.fulfilled, (state, action) => {
      const { members, matrimonies, ...family } = action.payload;
      Object.keys(state).forEach((key) => {
        state[key] = family[key];
      });
    });
  },
});

export const selectFamily = (state: RootState): Family => state.family;

export const familyReducer = familySlice.reducer;
