import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import {
  fetchFamily,
  loadFamily,
  selectFamily,
} from '../store/slices/family.slice';
import {
  selectMemberById,
  selectMemberIds,
} from '../store/slices/members.slice';

export function useFetchFamily(reload = false) {
  const rootMember = useAppSelector(selectFamily)['rootMember'];
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (rootMember && !reload) {
      dispatch(loadFamily('makari'));
    }
    dispatch(fetchFamily('makari'));
  }, [dispatch, rootMember, reload]);
}

// export function useFetchFamilyExtended(treeCreated) {
//   const dispatch = useAppDispatch();
//   useEffect(() => {
//     if (treeCreated) {
//       dispatch(fetchFamily('makari'));
//     }
//   }, [dispatch, fetchFamily, treeCreated]);
// }

export function useMemberById(id) {
  return useAppSelector((state) => selectMemberById(state, id));
}

export function useMemberIds() {
  return useAppSelector(selectMemberIds);
}
