import { useAppDispatch, useAppSelector } from './redux.hooks';
import {
  decrementZoom,
  incrementZoom,
  resetZoom,
  selectTreeZoom,
} from '../store/slices/tree.slice';
import { useCallback } from 'react';

export function useTreeZoomer() {
  const dispatch = useAppDispatch();
  const increment = useCallback(() => dispatch(incrementZoom()), [dispatch]);
  const decrement = useCallback(() => dispatch(decrementZoom()), [dispatch]);
  const reset = useCallback(() => dispatch(resetZoom()), [dispatch]);
  return [increment, decrement, reset];
}

export function useTreeZoom() {
  const zoom = useAppSelector(selectTreeZoom);
  return zoom;
}
