import React, { useRef } from 'react';
import { Button } from '@material-ui/core';
import { roundButtonStyle } from '../styles/round-button.style';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTreeRolling } from '../hooks/rolling.hooks';
import { useGoToRoot } from '../hooks/search.hooks';

export function TreeRoller() {
  const buttonRef = useRef(null);
  const [showMenu, setShowMenu] = React.useState(false);
  const {
    hasSomeoneSecondGenerationUnrolled,
    hasSomeoneRolled,
    rollSecondGeneration,
    unrollAll,
  } = useTreeRolling();
  const goToRoot = useGoToRoot();

  const onClickShowFirstGeneration = () => {
    setShowMenu(false);
    rollSecondGeneration();
  };

  const onClickShowAll = () => {
    setShowMenu(false);
    unrollAll();
  };

  const onClickToRoot = () => {
    setShowMenu(false);
    goToRoot();
  };

  return (
    <>
      <Button
        sx={roundButtonStyle}
        color={'primary'}
        variant={'contained'}
        ref={buttonRef}
        onClick={() => setShowMenu(true)}
      >
        <MoreVertIcon />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={buttonRef.current}
        keepMounted
        open={showMenu}
        onClose={() => setShowMenu(false)}
      >
        <MenuItem onClick={onClickToRoot}>Перейти до коренів</MenuItem>

        {hasSomeoneSecondGenerationUnrolled && (
          <MenuItem onClick={onClickShowFirstGeneration}>
            Показати лише перше покоління
          </MenuItem>
        )}
        {hasSomeoneRolled && (
          <MenuItem onClick={onClickShowAll}>Показати всі покоління</MenuItem>
        )}
      </Menu>
    </>
  );
}
