export enum TreeLoadStage {
  NONE,
  BUILD_VIRTUAL,
  CALCULATE_GELOMETRY,
  CALCULATE_MEMBERS_GELOMETRY,
  CALCULATE_SCROLL_SHIFT,
  CALCULATE_RELATIONS_GELOMETRY,
  SETTLE_RELOAD_DATA,
  RENDER_TREE,
}
