import { configureStore } from '@reduxjs/toolkit';
import { familyReducer } from './slices/family.slice';
import { notificationsReducer } from './slices/notifications.slice';
import { membersReducer } from './slices/members.slice';
import { matrimoniesReducer } from './slices/matrimonies.slice';
import { treeReducer } from './slices/tree.slice';
import { appReducer } from './slices/app.slice';

export const store = configureStore({
  reducer: {
    family: familyReducer,
    notifications: notificationsReducer,
    members: membersReducer,
    matrimonies: matrimoniesReducer,
    tree: treeReducer,
    app: appReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
