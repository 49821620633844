import React from 'react';
import { Link as RouterLink } from '@reach/router';
import MuiLink from '@material-ui/core/Link';

function Link({
  to,
  children,
  ...props
}: {
  to: string;
  children: React.ReactChild;
}) {
  const isAbsolute = !!(to.startsWith('http://') || to.startsWith('https://'));
  return isAbsolute ? (
    <MuiLink href={to} target="_blank" rel="noreferrer" {...props}>
      {children}
    </MuiLink>
  ) : (
    <MuiLink to={to} component={RouterLink} {...props}>
      {children}
    </MuiLink>
  );
}

export default Link;
