import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  selectMemberEntities,
  selectMemberMatrimoniesWithPartner,
  selectMembersParents,
} from './members.slice';
import { TreeBranch } from '../../interfaces/TreeBranch.interface';
import { fetchFamily, loadFamily, selectFamily } from './family.slice';
import { RootState } from '../store';
import { ComponentGeometry } from '../../interfaces/ComponentGeometry.interface';
import { MemberId } from '../../interfaces';
import { RelationGeometry } from '../../interfaces/RelationGeometry.interface';
import { TreeLoadStage } from '../../enums/TreeLoadStage.enum';
import { ScrollPosition } from '../../interfaces/ScrollPosition.interface';
import { ZOOM_MAX, ZOOM_MIN, ZOOM_STEP } from '../../const';

interface TreeState {
  loadStage: TreeLoadStage;
  isReload: boolean;
  timestamp: number;
  renderedMemberVirtualsTotal: number;
  geometry: ComponentGeometry;
  membersGeometry: Record<MemberId, ComponentGeometry>;
  relationsGeometry: RelationGeometry[];
  rolledMembers: MemberId[];
  target: MemberId;
  goTo: boolean;
  zoom: number;
  onReload: {
    renderedMemberVirtualsTotal: number;
    geometry: ComponentGeometry;
    membersGeometry: Record<MemberId, ComponentGeometry>;
    relationsGeometry: RelationGeometry[];
    scrollShift: ScrollPosition;
  };
}

const initialState = {
  loadStage: null,
  isReload: false,
  timestamp: null,
  renderedMemberVirtualsTotal: 0,
  geometry: null,
  membersGeometry: {},
  relationsGeometry: [],
  rolledMembers: [],
  target: null,
  goTo: false,
  zoom: 1,
  onReload: {
    renderedMemberVirtualsTotal: 0,
    geometry: null,
    membersGeometry: {},
    relationsGeometry: [],
    scrollShift: null,
  },
} as TreeState;

const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    resetVirtual(state) {
      state.renderedMemberVirtualsTotal = 0;
    },
    incrementRenderedMemberVirtualsTotal(state) {
      state.isReload
        ? state.onReload.renderedMemberVirtualsTotal++
        : state.renderedMemberVirtualsTotal++;
    },
    setTreeGeometry(state, action: PayloadAction<ComponentGeometry>) {
      state.isReload
        ? (state.onReload.geometry = action.payload)
        : (state.geometry = action.payload);
    },
    setMemberGeometry(
      state,
      action: PayloadAction<{ memberId: MemberId; data: ComponentGeometry }>,
    ) {
      state.isReload
        ? (state.onReload.membersGeometry[action.payload.memberId] =
            action.payload.data)
        : (state.membersGeometry[action.payload.memberId] =
            action.payload.data);
    },
    setScrollShift(state, action: PayloadAction<ScrollPosition>) {
      state.onReload.scrollShift = action.payload;
    },
    setRelationsGeometry(state, action: PayloadAction<RelationGeometry[]>) {
      state.isReload
        ? (state.onReload.relationsGeometry = action.payload)
        : (state.relationsGeometry = action.payload);
    },
    rollMembers(state, action: PayloadAction<MemberId[]>) {
      state.rolledMembers = Array.from(
        new Set([...state.rolledMembers, ...action.payload]),
      );
    },
    rollMembersExclusively(state, action: PayloadAction<MemberId[]>) {
      state.rolledMembers = Array.from(new Set(action.payload));
    },
    unrollMembers(state, action: PayloadAction<MemberId[]>) {
      state.rolledMembers = state.rolledMembers.filter(
        (m) => !action.payload.includes(m),
      );
    },
    settleOnReloadData(state) {
      state.geometry = state.onReload.geometry;
      state.membersGeometry = state.onReload.membersGeometry;
      state.relationsGeometry = state.onReload.relationsGeometry;
      // state.scrollShift = state.onReload.scrollShift
    },
    setTreeLoadNextStage(state) {
      state.loadStage++;
    },
    afterLoad(state) {
      state.loadStage = TreeLoadStage.NONE;
      if (state.isReload) {
        state.isReload = false;
        state.onReload = {
          renderedMemberVirtualsTotal: 0,
          geometry: null,
          membersGeometry: {},
          relationsGeometry: [],
          scrollShift: null,
        };
      }
    },
    setTreeTimestamp(state, action: PayloadAction<number>) {
      state.timestamp = action.payload;
    },
    setTarget(state, action: PayloadAction<MemberId>) {
      state.target = action.payload;
    },
    startReload(state) {
      state.isReload = true;
      state.loadStage = TreeLoadStage.BUILD_VIRTUAL;
    },
    incrementZoom(state) {
      if (state.zoom < ZOOM_MAX) {
        state.zoom += ZOOM_STEP;
      }
    },
    decrementZoom(state) {
      if (state.zoom > ZOOM_MIN) {
        state.zoom -= ZOOM_STEP;
      }
    },
    resetZoom(state) {
      state.zoom = 1;
    },
    goToTarget(state, action: PayloadAction<MemberId>) {
      state.target = action.payload;
      state.goTo = true;
    },
    resetGoTo(state) {
      state.goTo = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFamily.fulfilled, (state, action) => {
      state.loadStage = TreeLoadStage.BUILD_VIRTUAL;
    });
    builder.addCase(loadFamily.fulfilled, (state, action) => {
      state.loadStage = TreeLoadStage.BUILD_VIRTUAL;
    });
  },
});

export const {
  resetVirtual: resetTreeVirtual,
  incrementRenderedMemberVirtualsTotal,
  setTreeGeometry,
  setMemberGeometry,
  setScrollShift,
  setRelationsGeometry,
  rollMembers,
  rollMembersExclusively,
  unrollMembers,
  settleOnReloadData,
  setTreeLoadNextStage,
  afterLoad,
  setTreeTimestamp,
  setTarget,
  startReload,
  incrementZoom,
  decrementZoom,
  resetZoom,
  goToTarget,
  resetGoTo,
} = treeSlice.actions;

export const selectTreeIsReload = (state: RootState) => state.tree.isReload;
export const selectTreeLoadStage = (state: RootState) => state.tree.loadStage;
export const selectTreeGeometry = (state: RootState) =>
  state.tree.isReload ? state.tree.onReload.geometry : state.tree.geometry;
export const selectRenderedMemberVirtualsTotal = (state: RootState) =>
  state.tree.isReload
    ? state.tree.onReload.renderedMemberVirtualsTotal
    : state.tree.renderedMemberVirtualsTotal;
export const selectTreeMembersGeometry = (state: RootState) =>
  state.tree.isReload
    ? state.tree.onReload.membersGeometry
    : state.tree.membersGeometry;
export const selectTreeRenderedMembersGeometry = (state: RootState) =>
  state.tree.membersGeometry;
export const selectTreeRelationsGeometry = (state: RootState) =>
  state.tree.isReload
    ? state.tree.onReload.relationsGeometry
    : state.tree.relationsGeometry;
export const selectScrollShift = (state: RootState) =>
  state.tree.onReload.scrollShift;
export const selectTreeTarget = (state: RootState) => state.tree.target;
export const selectRolledMembers = (state: RootState) =>
  state.tree.rolledMembers;
export const selectTreeTimestamp = (state: RootState) => state.tree.timestamp;
export const selectTreeZoom = (state: RootState) => state.tree.zoom;
export const selectTreeGoTo = (state: RootState) => state.tree.goTo;

export const selectTree = createSelector(
  selectFamily,
  selectMemberEntities,
  selectMemberMatrimoniesWithPartner,
  selectMembersParents,
  selectRolledMembers,
  (
    family,
    memberEntities,
    memberMatrimoniesWithPartner,
    memberParents,
    rolledMembers,
  ): TreeBranch => {
    if (!family || Object.keys(memberEntities).length === 0) {
      return null;
    }

    const ageComparer = (a, b) => {
      const memberA = memberEntities[a];
      const memberB = memberEntities[b];
      if (memberA.birthDate > memberB.birthDate) {
        return 1;
      } else if (memberA.birthDate < memberB.birthDate) {
        return -1;
      } else {
        return 0;
      }
    };

    const parseMember = (memberId: string): TreeBranch => {
      const member = memberEntities[memberId];
      const branch = {
        id: memberId,
        matrimonies: [],
        children: [],
      };
      if (memberMatrimoniesWithPartner[memberId]) {
        branch.matrimonies = memberMatrimoniesWithPartner[memberId].map(
          (ma) => ({ ...ma, children: [] }),
        );
      }
      // Use sortedChildren to show older siblings first
      // const sortedChildren = [...member.children].sort(ageComparer);
      member.children.forEach((childId) => {
        if (rolledMembers.includes(childId)) return;

        const childParents = memberParents[childId];
        if (childParents.length > 1) {
          branch.matrimonies
            .find((ma) => childParents.includes(ma.partnerId))
            .children.push(parseMember(childId));
        } else {
          branch.children.push(parseMember(childId));
        }
      });
      return branch;
    };

    return parseMember(family.rootMember);
  },
);

export const selectTreeMembersIds = createSelector(
  selectTree,
  (tree): MemberId[] => {
    if (!tree) {
      return [];
    }
    const ids = [];
    const parseBranch = (branch) => {
      ids.push(branch.id);
      branch.children.forEach(parseBranch);
      (branch.matrimonies || []).forEach(parseMatrimonyBranch);
    };
    const parseMatrimonyBranch = (branch) => {
      ids.push(branch.partnerId);
      branch.children.forEach(parseBranch);
    };
    parseBranch(tree);
    return ids;
  },
);

export const selectTreeMembersTotal = createSelector(
  selectTreeMembersIds,
  (membersIds): number => membersIds.length,
);

export const selectAllTreeMemberVirtualsRendered = createSelector(
  selectRenderedMemberVirtualsTotal,
  selectTreeMembersTotal,
  (renderedMemberVirtualsTotal, treeMembersTotal): boolean =>
    renderedMemberVirtualsTotal === treeMembersTotal,
);

export const selectTreeGeometryCalculated = createSelector(
  selectTreeGeometry,
  (treeGeometry): boolean => {
    return !!treeGeometry;
  },
);
export const selectTreeMembersGeometryCalculated = createSelector(
  selectTreeMembersTotal,
  selectTreeMembersGeometry,
  (membersTotal, treeMembersGeometry): boolean => {
    return membersTotal === Object.keys(treeMembersGeometry).length;
  },
);
export const selectTreeRelationsGeometryCalculated = createSelector(
  selectTreeRelationsGeometry,
  (relationsGeometry): boolean => {
    return relationsGeometry.length > 0;
  },
);
export const selectScrollShiftCalculated = createSelector(
  selectScrollShift,
  (selectScrollShift): boolean => {
    return !!selectScrollShift;
  },
);

export const treeReducer = treeSlice.reducer;
