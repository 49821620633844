import React from 'react';
import Layout from '../components/Layout';
import { RouteComponentProps } from '@reach/router';
import TreeContainer from '../components/TreeContainer';
// import Zoomer from '../components/Zoomer'

function TreePage(props: RouteComponentProps) {
  return (
    <Layout>
      <TreeContainer />
      {/*<Zoomer />*/}
    </Layout>
  );
}

export default TreePage;
