import { ACCESS_TOKEN_ALIAS, REFRESH_TOKEN_ALIAS } from '../const';
import { authApi } from '../api/auth.api';
import { AccessTokens } from '../interfaces/AccessTokens.interface';

export function hasCredentialsInStorage(): boolean {
  return !!(
    window.localStorage.getItem(ACCESS_TOKEN_ALIAS) &&
    window.localStorage.getItem(REFRESH_TOKEN_ALIAS)
  );
}

function saveCredentials(data: AccessTokens): void {
  window.localStorage.setItem(ACCESS_TOKEN_ALIAS, data.accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_ALIAS, data.refreshToken);
}

export async function refreshAccessToken(): Promise<boolean> {
  if (!hasCredentialsInStorage) {
    return false;
  }
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_ALIAS);
  try {
    const data = await authApi.refreshAccessToken({
      refreshToken,
    });
    saveCredentials(data);
    return true;
  } catch (e) {
    return false;
  }
}
