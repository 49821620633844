import { Member, MemberId } from '../../interfaces';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  PayloadAction,
} from '@reduxjs/toolkit';
import { fetchFamily, selectFamily } from './family.slice';
import { RootState } from '../store';
import {
  selectMatrimonies,
  selectMatrimonyEntities,
} from './matrimonies.slice';
import { getMemberFullName } from '../../utils/members.utils';

const membersAdapter = createEntityAdapter<Member>();

export const membersSlice = createSlice({
  name: 'members',
  initialState: membersAdapter.getInitialState(),
  reducers: {
    // addMember: membersAdapter.addOne,
    // removeMember: membersAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFamily.fulfilled, (state, action) => {
      const { members } = action.payload;
      membersAdapter.addMany(state, members as Member[]);
    });
    // builder.addCase(fetchFamilyExtended.fulfilled, (state, action) => {
    //   const { members } = action.payload;
    //   membersAdapter.addMany(state, members as Member[]);
    // });
  },
});

// export const { addNotification, removeNotification } = membersSlice.actions;

export const {
  selectAll: selectMembers,
  selectEntities: selectMemberEntities,
  selectIds: selectMemberIds,
  selectById: selectMemberById,
  selectTotal: selectMembersTotal,
} = membersAdapter.getSelectors<RootState>((state) => state.members);

export const membersReducer = membersSlice.reducer;

export const selectMemberMatrimoniesWithPartner = createSelector(
  selectMemberIds,
  selectMatrimonies,
  (
    memberIds: MemberId[],
    matrimonies,
  ): Record<EntityId, Record<string, any>> => {
    return memberIds.reduce(
      (obj, memberId) => ({
        ...obj,
        [memberId]: matrimonies
          .filter((ma) => ma.members.includes(memberId))
          .map((ma) => ({
            id: ma.id,
            partnerId: ma.members.find((mId) => mId !== memberId),
          })),
      }),
      {},
    );
  },
);

export const selectMembersChildren = createSelector(
  selectMembers,
  (members) => {
    return members.reduce((obj, m) => ({ ...obj, [m.id]: m.children }), {});
  },
);

export const selectMembersDescendants = createSelector(
  selectFamily,
  selectMembersChildren,
  (family, membersChildIds) => {
    const membersDescendants = {} as Record<MemberId, MemberId[]>;
    const parseMember = (memberId: MemberId) => {
      const childIds = membersChildIds[memberId];
      membersDescendants[memberId] = [...childIds];
      if (childIds.length > 0) {
        Object.values(membersDescendants).forEach((descendants) => {
          if (descendants.includes(memberId)) {
            descendants.push(...childIds);
          }
        });
        childIds.forEach(parseMember);
      }
    };

    parseMember(family.rootMember);

    return membersDescendants;
  },
);

export const selectMembersAncestors = createSelector(
  selectFamily,
  selectMembersChildren,
  (family, membersChildIds) => {
    const membersAncestors = {} as Record<MemberId, MemberId[]>;
    const parseMember = (memberId: MemberId, ancestors: MemberId[] = []) => {
      membersAncestors[memberId] = [...ancestors];
      membersChildIds[memberId].forEach((childId) =>
        parseMember(childId, [...ancestors, memberId]),
      );
    };
    parseMember(family.rootMember);
    return membersAncestors;
  },
);

export const selectMembersParents = createSelector(
  selectMembers,
  (members): Record<MemberId, MemberId[]> => {
    return members.reduce(
      (obj, m) => ({
        ...obj,
        [m.id]: members
          .filter(({ children }) => children.includes(m.id))
          .map(({ id }) => id),
      }),
      {},
    );
  },
);

export const selectMembersInTrunk = createSelector(
  selectMemberIds,
  selectMembersParents,
  selectFamily,
  (memberIds: MemberId[], memberParents, family): MemberId[] =>
    memberIds.filter(
      (id) => family.rootMember === id || memberParents[id].length > 0,
    ),
);

export const selectMembersFullNames = createSelector(
  selectMembers,
  (members) => {
    return members.reduce((obj, member) => {
      obj[member.id] = getMemberFullName(member);
      return obj;
    }, {});
  },
);
