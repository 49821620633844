// import { store } from '../store';

import { ACCESS_TOKEN_ALIAS, REFRESH_TOKEN_ALIAS } from '../const';
import { refreshAccessToken } from '../utils/auth.utils';

export async function request<T>(
  url,
  options: Record<string, any> = {},
  refreshingToken = false,
): Promise<T> {
  // Normalize url
  const apiUrl =
    url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `${process.env.REACT_APP_API_URL}/${url}`;

  // Set default request options
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
    },
  };

  // POST,PATCH,PUT
  // if (['POST', 'PATCH', 'PUT'].includes(options.method)) {
  //   defaultOptions.headers['Content-Type'] = 'application/json'
  // }

  // Finalize options
  const requestOptions = {
    ...defaultOptions,
    ...options,
    headers: { ...defaultOptions.headers, ...(options.headers || {}) },
  } as Record<string, any>;
  // Handle json request
  if (requestOptions.body) {
    if (!(requestOptions.body instanceof FormData)) {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(requestOptions.body);
    }
  }

  // Add access token
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_ALIAS);
  if (accessToken) {
    requestOptions.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  // Fetch request
  return (
    fetch(apiUrl, requestOptions)
      // .then(response => {
      //   return response.ok ? response : Promise.reject(response.statusText)
      // })
      .then((response) => {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return response.json() as Promise<T>;
        }
        return Promise.reject(new Error('This content type not excpected.'));
      })
      .then((data: Record<string, any>) => {
        if (data.statusCode === 401 && accessToken && !refreshingToken) {
          return refreshAccessToken().then((accessRefreshed) => {
            if (accessRefreshed) {
              return request(url, options);
            } else {
              return Promise.reject(new Error(data.message));
            }
          });
        } else if (data.statusCode >= 400) {
          return Promise.reject(new Error(data.message));
        }
        return data as T;
      })
  );
}

async function get<T>(
  url: string,
  options: Record<string, any> = {},
): Promise<T> {
  options.method = 'GET';
  return request<T>(url, options);
}

async function post<T>(
  url: string,
  body: Record<string, any>,
  options: Record<string, any> = {},
): Promise<T> {
  options.method = 'POST';
  options.body = body;
  return request<T>(url, options);
}

async function put<T>(
  url: string,
  body: Record<string, any>,
  options: Record<string, any> = {},
): Promise<T> {
  options.method = 'PUT';
  options.body = body;
  return request<T>(url, options);
}

async function remove<T>(
  url: string,
  options: Record<string, any> = {},
): Promise<T> {
  options.method = 'DELETE';
  return request<T>(url, options);
}

export { get, post, put, remove };
