import { useAppDispatch, useAppSelector } from './redux.hooks';
import {
  rollMembers,
  unrollMembers,
  selectRolledMembers,
  setTarget,
  startReload,
  rollMembersExclusively,
  selectTreeTimestamp,
  resetGoTo,
  goToTarget,
} from '../store/slices/tree.slice';
import { useMemberChildren } from './member.hooks';
import { useCallback, useMemo } from 'react';
import {
  selectMemberIds,
  selectMembersAncestors,
  selectMembersChildren,
  selectMembersDescendants,
} from '../store/slices/members.slice';
import { selectFamily } from '../store';
import { MemberId } from '../interfaces';

export function useRolling(memberId) {
  const dispatch = useAppDispatch();
  const membersChildIds = useAppSelector(selectMembersChildren);
  const childIds = membersChildIds[memberId];
  const rolledMembers = useAppSelector(selectRolledMembers);
  const memberDescendantIds = useAppSelector(selectMembersDescendants)[
    memberId
  ];
  const memberAncestorIds = useAppSelector(selectMembersAncestors)[memberId];

  const hasUnrolledChildren = useMemo(() => {
    return (
      // childIds.length > 0 &&
      childIds.some((childId) => !rolledMembers.includes(childId))
    );
  }, [childIds, memberId, rolledMembers]);

  const hasRolledChildren = useMemo(() => {
    return (
      // childIds.length > 0 &&
      childIds.some((childId) => rolledMembers.includes(childId))
    );
  }, [childIds, memberId, rolledMembers]);

  const hasRolledDescendantsAfterChildren = useMemo(() => {
    return (
      memberDescendantIds.length > childIds.length &&
      memberDescendantIds
        .filter((descendantId) => !childIds.includes(descendantId))
        .some((descendantId) => rolledMembers.includes(descendantId))
    );
  }, [childIds, memberDescendantIds, rolledMembers]);

  const rollChildren = useCallback(() => {
    dispatch(rollMembers(childIds));
    dispatch(setTarget(memberId));
    dispatch(startReload());
  }, [dispatch, memberId, rollMembers, childIds]);

  const unrollChildren = useCallback(() => {
    const grandChildIds = childIds
      .map((childId) => membersChildIds[childId])
      .flat();
    dispatch(unrollMembers(childIds));
    dispatch(rollMembers(grandChildIds));
    dispatch(setTarget(memberId));
    dispatch(startReload());
  }, [dispatch, memberId, unrollMembers, childIds, membersChildIds]);

  const unrollDescendants = useCallback(() => {
    dispatch(unrollMembers(memberDescendantIds));
    dispatch(setTarget(memberId));
    dispatch(startReload());
  }, [dispatch, memberId, unrollMembers, memberDescendantIds]);

  const unrollLineExclusively = useCallback(() => {
    const idsToRoll = memberAncestorIds
      .map((ancestorId, ancestorIndex) =>
        membersChildIds[ancestorId].filter(
          (ancestorChildIds) =>
            !ancestorChildIds.includes(
              memberAncestorIds[ancestorIndex + 1] || memberId,
            ),
        ),
      )
      .flat();

    dispatch(rollMembersExclusively(idsToRoll));
    dispatch(setTarget(memberId));
    dispatch(startReload());
  }, [dispatch, memberId, memberAncestorIds, membersChildIds]);

  return {
    hasUnrolledChildren,
    hasRolledChildren,
    hasRolledDescendantsAfterChildren,
    rollChildren,
    unrollChildren,
    unrollDescendants,
    unrollLineExclusively,
  };
}

export function useTreeRolling() {
  const dispatch = useAppDispatch();
  const family = useAppSelector(selectFamily);
  const membersChildren = useAppSelector(selectMembersChildren);
  const memberIds = useAppSelector(selectMemberIds);
  const rolledMemberIds = useAppSelector(selectRolledMembers);
  const treeReady = !!useAppSelector(selectTreeTimestamp);

  const hasSomeoneSecondGenerationUnrolled = useMemo((): boolean => {
    if (!treeReady) {
      return false;
    }
    const secondGenerationIds = membersChildren[family.rootMember]
      .map((childId) => membersChildren[childId])
      .flat();
    return secondGenerationIds.some((id) => !rolledMemberIds.includes(id));
  }, [family, membersChildren, rolledMemberIds, treeReady]);

  const hasSomeoneRolled = useMemo((): boolean => {
    if (!treeReady) {
      return false;
    }
    return rolledMemberIds.length > 0;
  }, [rolledMemberIds, treeReady]);

  const rollSecondGeneration = useCallback(() => {
    const secondGenerationIds = membersChildren[family.rootMember]
      .map((childId) => membersChildren[childId])
      .flat();

    dispatch(goToTarget(family.rootMember));
    dispatch(rollMembersExclusively(secondGenerationIds));
    // dispatch(setTarget(null));
    dispatch(startReload());
    dispatch(goToTarget(family.rootMember));
  }, [family, membersChildren, dispatch]);

  const unrollAll = useCallback(() => {
    dispatch(goToTarget(family.rootMember));
    dispatch(unrollMembers(memberIds as MemberId[]));
    // dispatch(setTarget(null));
    dispatch(startReload());
  }, [memberIds, dispatch]);

  return {
    hasSomeoneSecondGenerationUnrolled,
    hasSomeoneRolled,
    rollSecondGeneration,
    unrollAll,
  };
}
