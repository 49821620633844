import { useAppSelector } from './redux.hooks';
import {
  selectMembersChildren,
  selectMembersInTrunk,
} from '../store/slices/members.slice';

export function useMemberInTrunk(memberId) {
  const trunkMembers = useAppSelector(selectMembersInTrunk);
  return trunkMembers.includes(memberId);
}

export function useMemberChildren(memberId) {
  const membersChildren = useAppSelector(selectMembersChildren);
  return membersChildren[memberId];
}
