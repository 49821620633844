import React, { useRef } from 'react';
import { useScrollShift, useScrollToMember } from '../hooks/scroll.hooks';
import { Box } from '@material-ui/core';
import { useTreeGeometryToRender } from '../hooks/tree.hooks';

function DraggableContainerComponent({ children }, draggableRef) {
  const treeGeometry = useTreeGeometryToRender();

  useScrollShift(draggableRef);

  useScrollToMember(draggableRef);

  return (
    <Box
      id="draggable-container"
      ref={draggableRef}
      sx={{
        position: 'relative',
        pointerEvents: 'none',
        touchAction: 'none',
        minHeight: '100%',
        minWidth: '100%',
        width: !treeGeometry
          ? 'auto'
          : treeGeometry.right - treeGeometry.left + 'px',
        height: !treeGeometry
          ? 'auto'
          : treeGeometry.bottom - treeGeometry.top + 'px',
      }}
    >
      {children}
    </Box>
  );
}

export const DraggableContainer = React.memo(
  React.forwardRef(DraggableContainerComponent),
);
