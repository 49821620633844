import React from 'react';
import { Router } from '@reach/router';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import { ThemeProvider } from '@emotion/react';
import { theme } from './App.theme';
import TreePage from '../pages/Tree.page';
import { Provider } from 'react-redux';
import { store } from '../store';
import { Auther } from './Auther';
import { LoginPage } from '../pages/Login.page';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Auther>
            <Router>
              <TreePage path="/" />
              <LoginPage path="/login" />
            </Router>
          </Auther>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
