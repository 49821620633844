import React, { useRef } from 'react';
import {
  useCalculateRelationsGeometry,
  useTree,
  useCalculateTreeGeometry,
  useTreeLoadStages,
  useCalculateScrollShift,
} from '../hooks/tree.hooks';
import { Box } from '@material-ui/core';
import MemberGroupVirtual from './MemberGroupVirtual';

export default function TreeVirtual() {
  const tree = useTree();
  const treeRef = useRef(null);

  useCalculateTreeGeometry(treeRef);

  useCalculateScrollShift();

  useCalculateRelationsGeometry();

  useTreeLoadStages();

  return (
    <Box
      ref={treeRef}
      sx={{
        display: 'inline-block',
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '16px 32px',
        pointerEvents: 'none',
        minWidth: '100%',
        boxSizing: 'border-box',
        textAlign: 'center',
        visibility: 'hidden',
      }}
    >
      <MemberGroupVirtual group={tree} />
    </Box>
  );
}
