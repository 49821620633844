import React from 'react';
import {
  TreeBranch,
  TreeMatrimonyBranch,
} from '../interfaces/TreeBranch.interface';
import { Box } from '@material-ui/core';
import { MEMBER_DISTANCE_X, MEMBER_DISTANCE_Y } from '../const';
import MemberVirtual from './MemberVirtual';
import MemberGroupVirtual from './MemberGroupVirtual';

export default function MatrimonyVirtual({
  member,
  isPrimary = false,
  matrimony,
}: {
  member?: Omit<TreeBranch, 'matrimonies'>;
  isPrimary?: boolean;
  matrimony: TreeMatrimonyBranch;
}) {
  const children = isPrimary
    ? [...(member.children || []), ...(matrimony.children || [])]
    : matrimony.children || [];

  return (
    <Box
      sx={{
        display: 'inline-block',
        overflow: 'visible',
        verticalAlign: 'top',
        pointerEvents: 'none',
        '&:not(:first-of-type)': {
          paddingLeft: MEMBER_DISTANCE_X,
        },
      }}
    >
      {isPrimary && <MemberVirtual memberId={member.id} />}

      <Box
        sx={{
          display: 'inline-block',
          overflow: 'visible',
          paddingLeft: MEMBER_DISTANCE_X,
        }}
      >
        <MemberVirtual memberId={matrimony.partnerId} isPartner={true} />
      </Box>

      <Box
        sx={{
          paddingTop: MEMBER_DISTANCE_Y,
          whiteSpace: 'nowrap',
          overflow: 'visible',
          pointerEvents: 'none',
        }}
      >
        {children.map((child) => (
          <MemberGroupVirtual group={child} key={child.id} />
        ))}
      </Box>
    </Box>
  );
}
