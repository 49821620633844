export const MEMBER_DISTANCE_X = '40px';
export const MEMBER_DISTANCE_Y = '60px';
export const MEMBER_HEIGHT = '100px';
export const MEMBER_IMAGE_RATIO = 1;
export const MATRIMONY_STEP_DISTANCE = '40px';

// Zoom
export const ZOOM_STEP = 0.1;
export const ZOOM_MAX = 1.6;
export const ZOOM_MIN = 0.3;

export const ACCESS_TOKEN_ALIAS = 'accessToken';
export const REFRESH_TOKEN_ALIAS = 'refreshToken';
