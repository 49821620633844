import React from 'react';
import { TreeBranch } from '../interfaces/TreeBranch.interface';
import { Box } from '@material-ui/core';
import { MEMBER_DISTANCE_X, MEMBER_DISTANCE_Y } from '../const';
import MatrimonyVirtual from './MatrimonyVirtual';
import MemberVirtual from './MemberVirtual';

export default function MemberGroupVirtual({
  group: { matrimonies, ...group },
}: {
  group: TreeBranch;
}) {
  const [mainMatrimony, ...otherMatrimonies] = matrimonies || [];
  return (
    <Box
      sx={{
        display: 'inline-block',
        overflow: 'visible',
        verticalAlign: 'top',
        pointerEvents: 'none',
        whiteSpace: 'nowrap',
        ':not(:first-of-type)': {
          paddingLeft: MEMBER_DISTANCE_X,
        },
      }}
    >
      {mainMatrimony ? (
        <MatrimonyVirtual
          member={group}
          isPrimary={true}
          matrimony={mainMatrimony}
        />
      ) : (
        <MemberVirtual memberId={group.id} />
      )}

      {otherMatrimonies &&
        otherMatrimonies.map((matrimony) => (
          <MatrimonyVirtual matrimony={matrimony} key={matrimony.id} />
        ))}

      {/*Kind of an antipattern. Need refactoring*/}
      {!mainMatrimony && group.children && (
        <Box
          sx={{
            paddingTop: MEMBER_DISTANCE_Y,
            whiteSpace: 'nowrap',
            // display: 'flex',
            pointerEvents: 'none',
          }}
        >
          {group.children &&
            group.children.map((child) => (
              <MemberGroupVirtual group={child} key={child.id} />
            ))}
        </Box>
      )}
    </Box>
  );
}
