import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import Menu from './Menu';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Notifier } from './Notifier';

function Layout({
  children,
}: {
  children: ReactJSXElement | ReactJSXElement[];
}) {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <AppBar position="static" sx={{ zIndex: 'appBar' }}>
        <Toolbar>
          <Link href="/" color="inherit" sx={{ textDecoration: 'none' }}>
            <Typography variant="h5" component="h1">
              Родина Макарів
            </Typography>
          </Link>
          <IconButton
            sx={{ marginLeft: 'auto', marginRight: '18px' }}
            color="inherit"
            aria-label="open menu"
            onClick={() => setOpenMenu((val) => !val)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={openMenu} onClose={() => setOpenMenu(false)}>
        <Menu onClose={() => setOpenMenu(false)} />
      </Drawer>

      {children}
      <Notifier />
    </>
  );
}

export default Layout;
