import React from 'react';
import { Alert, Snackbar } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../hooks/redux.hooks';
import { removeNotification, selectNotifications } from '../store';

export function Notifier() {
  const notifications = useAppSelector(selectNotifications);
  const dispatch = useAppDispatch();

  const notificationToShow = notifications.length > 0 ? notifications[0] : null;

  const handleClose = () => dispatch(removeNotification(notificationToShow.id));

  return (
    <Snackbar
      open={!!notificationToShow}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      {notificationToShow && (
        <Alert onClose={handleClose} severity={notificationToShow.type}>
          {notificationToShow.message}
        </Alert>
      )}
    </Snackbar>
  );
}
