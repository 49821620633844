import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface AppState {
  isAuthenticated: boolean;
}

const initialState = {
  isAuthenticated: false,
} as AppState;

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;

export const { setIsAuthenticated } = appSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.app.isAuthenticated;
