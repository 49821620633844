import React from 'react';
import { useMemberById } from '../hooks/family.hooks';
import { MemberId } from '../interfaces';
import { Box, Card, CardMedia, Typography } from '@material-ui/core';
import { MEMBER_HEIGHT } from '../const';
import { useMemberInTrunk } from '../hooks/member.hooks';
import Roller from './Roller';
import { ComponentGeometry } from '../interfaces/ComponentGeometry.interface';
import { getMemberFullName } from '../utils/members.utils';

function Member({
  id,
  geometry,
}: {
  id: MemberId;
  geometry: ComponentGeometry;
}) {
  const member = useMemberById(id);
  const isInTrunk = useMemberInTrunk(id);

  return (
    <Card
      id={id}
      sx={{
        height: MEMBER_HEIGHT,
        cursor: 'auto',
        pointerEvents: 'auto',
        userSelect: 'auto',
        position: 'absolute',
        left: geometry.left,
        top: geometry.top,
        width: geometry.right - geometry.left,
        transition: 'left 1s, top 1s',
        boxShadow: 1,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {member?.image?.thumbnail && (
          <CardMedia
            image={`${process.env.REACT_APP_UPLOADS_BASE_URL}/${member.image.thumbnail}`}
            sx={{
              width: MEMBER_HEIGHT,
              height: MEMBER_HEIGHT,
              filter: member.isDead ? 'grayscale()' : 'none',
            }}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 16px',
            textAlign: 'left',
          }}
        >
          <Typography variant="subtitle1">
            {getMemberFullName(member)}
          </Typography>
        </Box>
      </Box>

      {isInTrunk && (
        <Box sx={{ position: 'absolute', right: 0, bottom: 0 }}>
          <Roller memberId={id} />
        </Box>
      )}
    </Card>
  );
}

export default React.memo(Member);
