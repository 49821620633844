import React, { useRef, useState } from 'react';
import { Autocomplete, Button, Popover, TextField } from '@material-ui/core';
import { useGoToMember, useSearchOptions } from '../hooks/search.hooks';
import { roundButtonStyle } from '../styles/round-button.style';
import SearchIcon from '@material-ui/icons/Search';
import { MemberId } from '../interfaces';

export function Searcher() {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const options = useSearchOptions();
  const goToMember = useGoToMember();

  return (
    <>
      <Button
        ref={buttonRef}
        sx={roundButtonStyle}
        color={'primary'}
        variant={'contained'}
        onClick={() => setOpen(true)}
      >
        <SearchIcon />
      </Button>
      <Popover
        id={'search-popover'}
        PaperProps={{ sx: {}, elevation: 3 }}
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: 250 }}
              variant={'filled'}
              label="Пошук"
            />
          )}
          options={options}
          renderOption={(props, option) => (
            <li {...props} key={option.memberId}>
              {option.label}
            </li>
          )}
          onChange={(
            e,
            selectedOption: { memberId: MemberId; label: string },
            reason,
          ) => {
            if (reason === 'selectOption') {
              setOpen(false);
              goToMember(selectedOption.memberId);
            }
          }}
        />
      </Popover>
    </>
  );
}
