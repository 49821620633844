import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { validationMessages } from '../utils/validation.utils';
import { authApi } from '../api/auth.api';
import { AccessTokens } from '../interfaces/AccessTokens.interface';
import { AccessCredentials } from '../interfaces/AccessCredentials.interface';
import { useLogin } from '../hooks/auth.hooks';
import { addNotification } from '../store';
import { NotificationType } from '../enums';

function LoginForm() {
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch();
  const login = useLogin();

  const onSubmit = async (data: AccessCredentials) => {
    try {
      const tokens = await authApi.login(data);
      login(tokens);
    } catch (e) {
      dispatch(
        addNotification({ message: e.message, type: NotificationType.ERROR }),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="email"
        rules={{
          required: validationMessages.required,
          pattern: {
            value: /^\S+@\S+\.\S+$/,
            message: validationMessages.email,
          },
        }}
        render={({ field: { ref, value, ...fieldProps }, fieldState }) => (
          <TextField
            {...fieldProps}
            label="Email"
            fullWidth
            margin="normal"
            error={!!fieldState.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="password"
        rules={{ required: validationMessages.required }}
        render={({ field: { ref, value, ...fieldProps }, fieldState }) => (
          <TextField
            {...fieldProps}
            type="password"
            label="Password"
            fullWidth
            margin="normal"
            error={!!fieldState.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />

      <Button type="submit" color="primary" variant="contained" size="large">
        Login
      </Button>
    </form>
  );
}

export default LoginForm;
