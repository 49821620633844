import { useCallback, useEffect, useState } from 'react';
import { useDrag } from '@use-gesture/react';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import {
  resetGoTo,
  selectScrollShift,
  selectTreeGoTo,
  selectTreeIsReload,
  selectTreeLoadStage,
  selectTreeRenderedMembersGeometry,
  selectTreeTarget,
  selectTreeZoom,
} from '../store/slices/tree.slice';
import { TreeLoadStage } from '../enums/TreeLoadStage.enum';
import { selectFamily } from '../store';

export function useDragging(draggableRef) {
  const zoom = useAppSelector(selectTreeZoom);
  const setDraggedPosition = useCallback(
    (draggable, deltaX, deltaY) => {
      draggable.style.transition = 'none';
      draggable.style.left = draggable.offsetLeft + deltaX / zoom + 'px';
      draggable.style.top = draggable.offsetTop + deltaY / zoom + 'px';
    },
    [zoom],
  );
  return useDrag(({ down, delta: [deltaX, deltaY] }) => {
    if (down) {
      setDraggedPosition(draggableRef.current, deltaX, deltaY);
    }
  }, {});
}

export function useScrollShift(draggableRef) {
  const isReload = useAppSelector(selectTreeIsReload);
  const loadStage = useAppSelector(selectTreeLoadStage);
  const scrollShift = useAppSelector(selectScrollShift);

  useEffect(() => {
    if (isReload && loadStage === TreeLoadStage.RENDER_TREE && scrollShift) {
      draggableRef.current.style.transition = 'left 1s, top 1s';
      draggableRef.current.style.left =
        draggableRef.current.offsetLeft + scrollShift.left + 'px';
      draggableRef.current.style.top =
        draggableRef.current.offsetTop + scrollShift.top + 'px';
    }
  }, [isReload, loadStage, draggableRef, scrollShift, TreeLoadStage]);
}

export function useScrollToMember(draggableRef) {
  const goTo = useAppSelector(selectTreeGoTo);
  const target = useAppSelector(selectTreeTarget);
  const membersGeometry = useAppSelector(selectTreeRenderedMembersGeometry);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (goTo && target) {
      const targetGeometry = membersGeometry[target];

      draggableRef.current.style.transition = 'left 1s, top 1s';
      draggableRef.current.style.left =
        -targetGeometry.left + window.innerWidth / 2 - 200 + 'px';
      draggableRef.current.style.top =
        -targetGeometry.top + window.innerHeight / 2 - 200 + 'px';

      dispatch(resetGoTo());
    }
  }, [goTo, target, membersGeometry, dispatch]);
}

export function useScrollToRootAfterFirstLoad(createdAt, draggableRef) {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const family = useAppSelector(selectFamily);
  const rootId = family?.rootMember;
  const membersGeometry = useAppSelector(selectTreeRenderedMembersGeometry);
  const rootGeometry = membersGeometry[rootId];

  useEffect(() => {
    if (isFirstLoad && createdAt) {
      setIsFirstLoad(false);
      draggableRef.current.style.left =
        -rootGeometry.left + window.innerWidth / 2 - 200 + 'px';
      draggableRef.current.style.top = -rootGeometry.top + 32 + 'px';
    }
  }, [isFirstLoad, setIsFirstLoad, rootId, createdAt, rootGeometry]);
}
