import { useAppDispatch, useAppSelector } from './redux.hooks';
import { selectMembersFullNames } from '../store/slices/members.slice';
import { useCallback, useMemo } from 'react';
import { goToTarget } from '../store/slices/tree.slice';
import { selectFamily } from '../store';

export function useSearchOptions() {
  const membersFullNames = useAppSelector(selectMembersFullNames);
  const searchOptions = useMemo(
    () =>
      Object.keys(membersFullNames).map((memberId) => ({
        memberId,
        label: membersFullNames[memberId],
      })),
    [membersFullNames],
  );
  return searchOptions;
}

export function useGoToMember() {
  const dispatch = useAppDispatch();
  const goToMember = useCallback(
    (memberId) => {
      dispatch(goToTarget(memberId));
    },
    [dispatch],
  );

  return goToMember;
}

export function useGoToRoot() {
  const dispatch = useAppDispatch();
  const family = useAppSelector(selectFamily);
  const rootId = family?.rootMember;

  const goToRoot = useCallback(() => {
    if (rootId) dispatch(goToTarget(rootId));
  }, [rootId, dispatch]);

  return goToRoot;
}
