import { Matrimony } from '../../interfaces';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchFamily } from './family.slice';

const matrimoniesAdapter = createEntityAdapter<Matrimony>();

export const matrimoniesSlice = createSlice({
  name: 'matrimonies',
  initialState: matrimoniesAdapter.getInitialState(),
  reducers: {
    // addMatrimony: matrimoniesAdapter.addOne,
    // removeMatrimony: matrimoniesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFamily.fulfilled, (state, action) => {
      const { matrimonies } = action.payload;
      matrimoniesAdapter.addMany(state, matrimonies as Matrimony[]);
    });
  },
});

// export const { addNotification, removeNotification } = matrimoniesSlice.actions;

export const {
  selectAll: selectMatrimonies,
  selectEntities: selectMatrimonyEntities,
} = matrimoniesAdapter.getSelectors<RootState>((state) => state.matrimonies);

export const matrimoniesReducer = matrimoniesSlice.reducer;
