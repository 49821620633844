import React from 'react';
import { Stack } from '@material-ui/core';
import { Searcher } from './Searcher';
import { TreeRoller } from './TreeRoller';

export function TreeControls() {
  return (
    <>
      <Stack spacing={3} sx={{ position: 'fixed', top: '90px', right: '35px' }}>
        <Searcher />
        <TreeRoller />
      </Stack>
    </>
  );
}
