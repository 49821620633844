import React from 'react';
import { Box } from '@material-ui/core';

function DragContainerComponent({ children, bindDragging }) {
  return (
    <Box
      {...bindDragging()}
      sx={{
        width: `100%`,
        height: '100%',
        cursor: 'grab',
        touchAction: 'none',
      }}
    >
      {children}
    </Box>
  );
}

export const DragContainer = React.memo(DragContainerComponent);
