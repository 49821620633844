import React, { useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useRolling } from '../hooks/rolling.hooks';

function Roller({ memberId }) {
  const [showMenu, setShowMenu] = React.useState(false);
  const buttonRef = useRef(null);
  const {
    hasUnrolledChildren,
    hasRolledChildren,
    hasRolledDescendantsAfterChildren,
    rollChildren,
    unrollChildren,
    unrollDescendants,
    unrollLineExclusively,
  } = useRolling(memberId);

  const toggleMenu = () => setShowMenu((val) => !val);
  const onClose = () => {
    setShowMenu(false);
  };
  const onRollChildrenClick = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    rollChildren();
  };
  const onUnrollChildrenClick = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    unrollChildren();
  };
  const onUnrollDescendantsClick = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    unrollDescendants();
  };
  const onUnrollLineExclusivelyClick = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    unrollLineExclusively();
  };

  // if (!hasChildren) {
  //   return null;
  // }
  return (
    <>
      <IconButton
        aria-label="add"
        color="primary"
        ref={buttonRef}
        onClick={toggleMenu}
      >
        {hasRolledChildren ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={buttonRef.current}
        keepMounted
        open={showMenu}
        onClose={onClose}
      >
        {hasUnrolledChildren && (
          <MenuItem onClick={onRollChildrenClick}>Сховати нащадків</MenuItem>
        )}
        {hasRolledChildren && (
          <MenuItem onClick={onUnrollChildrenClick}>
            Показати наступне покоління
          </MenuItem>
        )}
        {hasRolledDescendantsAfterChildren && (
          <MenuItem onClick={onUnrollDescendantsClick}>
            Показати усіх нащадків
          </MenuItem>
        )}
        <MenuItem onClick={onUnrollLineExclusivelyClick}>
          Показати лише цю гілку
        </MenuItem>
      </Menu>
    </>
  );
}

export default Roller;
