import { useAppDispatch, useAppSelector } from './redux.hooks';
import {
  incrementRenderedMemberVirtualsTotal,
  afterLoad,
  resetTreeVirtual,
  selectAllTreeMemberVirtualsRendered,
  setTreeGeometry,
  selectScrollShiftCalculated,
  selectTree,
  selectTreeGeometry,
  selectTreeGeometryCalculated,
  selectTreeIsReload,
  selectTreeLoadStage,
  selectTreeMembersGeometry,
  selectTreeMembersGeometryCalculated,
  selectTreeMembersIds,
  selectTreeRelationsGeometryCalculated,
  selectTreeTarget,
  settleOnReloadData,
  setTreeLoadNextStage,
  setScrollShift,
  setMemberGeometry,
  setRelationsGeometry,
  selectTreeTimestamp,
  setTreeTimestamp,
  selectTreeRenderedMembersGeometry,
  selectTreeRelationsGeometry,
} from '../store/slices/tree.slice';
import {
  TreeBranch,
  TreeMatrimonyBranch,
} from '../interfaces/TreeBranch.interface';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ComponentGeometry } from '../interfaces/ComponentGeometry.interface';
import {
  Point,
  RelationGeometry,
} from '../interfaces/RelationGeometry.interface';
import {
  MATRIMONY_STEP_DISTANCE,
  MEMBER_DISTANCE_X,
  MEMBER_DISTANCE_Y,
} from '../const';
import { TreeLoadStage } from '../enums/TreeLoadStage.enum';
import { selectMembersDescendants } from '../store/slices/members.slice';

export function useBuildTreeVirtual() {
  const loadStage = useAppSelector(selectTreeLoadStage);
  return loadStage >= TreeLoadStage.BUILD_VIRTUAL;
}

export function useTree() {
  return useAppSelector(selectTree) as TreeBranch;
}

export function useTreeMembersIds() {
  return useAppSelector(selectTreeMembersIds);
}

export function useMemberVirtualRendered() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(incrementRenderedMemberVirtualsTotal());
  }, [dispatch, incrementRenderedMemberVirtualsTotal]);
}

export function useCalculateTreeGeometry(treeRef) {
  const loadStage = useAppSelector(selectTreeLoadStage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadStage === TreeLoadStage.CALCULATE_GELOMETRY) {
      const geo = {};
      // Count tree geometry after tree rendering
      requestAnimationFrame(() => {
        const rect = treeRef.current.getBoundingClientRect();
        ['left', 'top', 'right', 'bottom'].forEach((key) => {
          geo[key] = Math.round(rect[key]);
        });
        dispatch(setTreeGeometry(geo as ComponentGeometry));
      });
    }
  }, [
    treeRef,
    setTreeGeometry,
    loadStage,
    dispatch,
    TreeLoadStage,
    requestAnimationFrame,
  ]);
}

export function useCalculateMemberGeometry(id, ref) {
  const treeGeo = useAppSelector(selectTreeGeometry);
  const loadStage = useAppSelector(selectTreeLoadStage);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (loadStage === TreeLoadStage.CALCULATE_MEMBERS_GELOMETRY) {
      const rect = ref.current.getBoundingClientRect();
      const geo = {
        left: Math.round(rect.left - treeGeo.left),
        right: Math.round(rect.right - treeGeo.left),
        top: Math.round(rect.top - treeGeo.top),
        bottom: Math.round(rect.bottom - treeGeo.top),
      };

      dispatch(setMemberGeometry({ memberId: id, data: geo }));
    }
  }, [treeGeo, id, ref, loadStage, dispatch, TreeLoadStage]);
}

export function useCalculateScrollShift() {
  const isReload = useAppSelector(selectTreeIsReload);
  const loadStage = useAppSelector(selectTreeLoadStage);
  const membersGeometry = useAppSelector(selectTreeRenderedMembersGeometry);
  const reloadedMembersGeometry = useAppSelector(selectTreeMembersGeometry);
  const targetId = useAppSelector(selectTreeTarget);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      isReload &&
      targetId &&
      loadStage === TreeLoadStage.CALCULATE_SCROLL_SHIFT
    ) {
      const targetOldGeo = membersGeometry[targetId];
      const targetNewGeo = reloadedMembersGeometry[targetId];
      const shift = {
        top: targetOldGeo.top - targetNewGeo.top,
        left: targetOldGeo.left - targetNewGeo.left,
      };

      dispatch(setScrollShift(shift));
    }
  }, [
    targetId,
    membersGeometry,
    reloadedMembersGeometry,
    isReload,
    loadStage,
    dispatch,
    setScrollShift,
    TreeLoadStage,
  ]);
}

export function useCalculateRelationsGeometry() {
  const tree = useAppSelector(selectTree);
  const loadStage = useAppSelector(selectTreeLoadStage);
  const membersGeo = useAppSelector(selectTreeMembersGeometry);
  const dispatch = useAppDispatch();

  const calculateGroupRelationsGeometry = useCallback(
    (member, geo) => {
      const memberGeo = membersGeo[member.id];

      // Matrimonies
      if (member.matrimonies && member.matrimonies.length > 0) {
        const matrimoniesStartPoint = {
          x: memberGeo.right,
          y: memberGeo.top + parseInt(MATRIMONY_STEP_DISTANCE) / 2,
        };
        member.matrimonies.forEach((m) =>
          calculateMatrimonyRelationGeometry(m, matrimoniesStartPoint, geo),
        );
      }

      // Children
      if (member.children && member.children.length > 0) {
        const childrenRelationPoint = {
          x: (memberGeo.right - memberGeo.left) / 2 + memberGeo.left,
          y: memberGeo.bottom,
        };
        member.children.forEach((c) =>
          calculateChildRelationGeometry(c, childrenRelationPoint, true, geo),
        );
      }
    },
    [membersGeo],
  );

  const calculateMatrimonyRelationGeometry = useCallback(
    (
      matrimony: TreeMatrimonyBranch,
      startPoint: Point,
      geo: RelationGeometry[],
    ) => {
      const partnerGeo = membersGeo[matrimony.partnerId];
      const relationGeo = [startPoint];
      relationGeo.push({
        x: partnerGeo.left + (partnerGeo.right - partnerGeo.left) / 2,
        y: startPoint.y,
      });
      relationGeo.push({
        x: relationGeo[1].x,
        y: relationGeo[1].y + parseInt(MATRIMONY_STEP_DISTANCE) / 2,
      });
      geo.push(relationGeo);

      // Matrimony children
      if (matrimony.children && matrimony.children.length > 0) {
        const childrenRelationPoint = {
          x: partnerGeo.left - parseInt(MEMBER_DISTANCE_X) / 2,
          y: partnerGeo.top - parseInt(MATRIMONY_STEP_DISTANCE) / 2,
        };
        matrimony.children.forEach((c) =>
          calculateChildRelationGeometry(c, childrenRelationPoint, false, geo),
        );
      }
    },
    [membersGeo],
  );
  const calculateChildRelationGeometry = useCallback(
    (
      child: TreeBranch,
      startPoint: Point,
      hasSingleParent = false,
      geo: RelationGeometry[],
    ) => {
      const childGeo = membersGeo[child.id];
      const endPoint = {
        x: (childGeo.right - childGeo.left) / 2 + childGeo.left,
        y: childGeo.top,
      };
      const relationGeo = [startPoint];
      if (startPoint.x !== endPoint.x) {
        const yCoord =
          endPoint.y -
          parseInt(MEMBER_DISTANCE_Y) / 2 -
          (hasSingleParent ? parseInt(MATRIMONY_STEP_DISTANCE) / 2 : 0);
        relationGeo.push({
          x: startPoint.x,
          y: yCoord,
        });
        relationGeo.push({
          x: endPoint.x,
          y: yCoord,
        });
      }
      relationGeo.push(endPoint);

      geo.push(relationGeo);

      // child group
      calculateGroupRelationsGeometry(child, geo);
    },
    [membersGeo],
  );

  useEffect(() => {
    if (loadStage === TreeLoadStage.CALCULATE_RELATIONS_GELOMETRY) {
      const geo = [] as RelationGeometry[];
      calculateGroupRelationsGeometry(tree, geo);
      dispatch(setRelationsGeometry(geo));
    }
  }, [
    tree,
    loadStage,
    membersGeo,
    dispatch,
    setRelationsGeometry,
    calculateGroupRelationsGeometry,
  ]);
}

// need to remove this
export function useTreeGeometryCalculated() {
  return useAppSelector(selectTreeGeometryCalculated);
}

export function useMemberGeometry(memberId) {
  const membersGeometry = useAppSelector(selectTreeMembersGeometry);
  return membersGeometry[memberId];
}

export function useTreeMembersToRender() {
  const [membersIdsToShow, setMembersIdsToShow] = useState([]);
  const treeMembersIds = useAppSelector(selectTreeMembersIds);
  const loadStage = useAppSelector(selectTreeLoadStage);

  useEffect(() => {
    if (loadStage === TreeLoadStage.RENDER_TREE) {
      setMembersIdsToShow(treeMembersIds);
    }
  }, [treeMembersIds, loadStage, setMembersIdsToShow, TreeLoadStage]);

  return membersIdsToShow;
}

export function useMembersGeometryToRender() {
  const [geometry, setGeometry] = useState(null);
  const membersGeometry = useAppSelector(selectTreeMembersGeometry);
  const loadStage = useAppSelector(selectTreeLoadStage);
  useEffect(() => {
    if (loadStage === TreeLoadStage.RENDER_TREE) {
      setGeometry(membersGeometry);
    }
  }, [setGeometry, membersGeometry, loadStage, TreeLoadStage]);
  return geometry;
}

export function useTreeGeometryToRender() {
  const [geometry, setGeometry] = useState(null);
  const treeGeometry = useAppSelector(selectTreeGeometry);
  const loadStage = useAppSelector(selectTreeLoadStage);
  useEffect(() => {
    if (loadStage === TreeLoadStage.RENDER_TREE) {
      setGeometry(treeGeometry);
    }
  }, [setGeometry, treeGeometry, loadStage, TreeLoadStage]);
  return geometry;
}

export function useRelationsGeometryToRender() {
  const [geometry, setGeometry] = useState(null);
  const relationsGeometry = useAppSelector(selectTreeRelationsGeometry);
  const loadStage = useAppSelector(selectTreeLoadStage);
  useEffect(() => {
    if (loadStage === TreeLoadStage.RENDER_TREE) {
      setTimeout(() => {
        setGeometry(relationsGeometry);
      }, 1000);
    }
  }, [setGeometry, relationsGeometry, loadStage, TreeLoadStage]);
  return geometry;
}

export function useTreeLoadStages() {
  const dispatch = useAppDispatch();
  const isReload = useAppSelector(selectTreeIsReload);
  const loadStage = useAppSelector(selectTreeLoadStage);
  const allMemberVirtualsRenderd = useAppSelector(
    selectAllTreeMemberVirtualsRendered,
  );
  const treeGeometryCalculated = useAppSelector(selectTreeGeometryCalculated);
  const membersGeometryCalculated = useAppSelector(
    selectTreeMembersGeometryCalculated,
  );
  const relationsGeometryCalculated = useAppSelector(
    selectTreeRelationsGeometryCalculated,
  );
  const scrollShiftCalculated = useAppSelector(selectScrollShiftCalculated);
  const targetId = useAppSelector(selectTreeTarget);

  useEffect(() => {
    switch (loadStage) {
      case TreeLoadStage.BUILD_VIRTUAL:
        if (allMemberVirtualsRenderd) {
          dispatch(setTreeLoadNextStage());
        }
        break;
      case TreeLoadStage.CALCULATE_GELOMETRY:
        if (treeGeometryCalculated) {
          dispatch(setTreeLoadNextStage());
        }
        break;
      case TreeLoadStage.CALCULATE_MEMBERS_GELOMETRY:
        if (membersGeometryCalculated) {
          dispatch(setTreeLoadNextStage());
        }
        break;
      case TreeLoadStage.CALCULATE_SCROLL_SHIFT:
        if (!isReload || !targetId || scrollShiftCalculated) {
          dispatch(setTreeLoadNextStage());
        }
        break;
      case TreeLoadStage.CALCULATE_RELATIONS_GELOMETRY:
        if (relationsGeometryCalculated) {
          dispatch(setTreeLoadNextStage());
        }
        break;
      case TreeLoadStage.SETTLE_RELOAD_DATA:
        if (isReload) {
          dispatch(settleOnReloadData());
        }
        dispatch(setTreeLoadNextStage());
        break;
      case TreeLoadStage.RENDER_TREE:
        dispatch(setTreeTimestamp(Date.now()));
        break;
      default:
        return;
    }
  }, [
    dispatch,
    isReload,
    loadStage,
    allMemberVirtualsRenderd,
    treeGeometryCalculated,
    membersGeometryCalculated,
    scrollShiftCalculated,
    relationsGeometryCalculated,
    requestAnimationFrame,
    targetId,
  ]);
}

export function useAfterLoad(createdAt) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (createdAt) {
      dispatch(afterLoad());
    }
  }, [dispatch, afterLoad, createdAt]);
}

export function useTreeTimestamp() {
  const treeTimestamp = useAppSelector(selectTreeTimestamp);
  return treeTimestamp;
}

export function useTreeKey() {
  const [key, setKey] = useState(null);
  const loadStage = useAppSelector(selectTreeLoadStage);
  useEffect(() => {
    if (loadStage === TreeLoadStage.RENDER_TREE) {
      setKey(Symbol());
    }
  }, [setKey, loadStage, TreeLoadStage, Symbol]);

  return key;
}
