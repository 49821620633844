import React, { useRef } from 'react';
import { useMemberById } from '../hooks/family.hooks';
import { MemberId } from '../interfaces';
import { Box, Card, CardMedia, Typography } from '@material-ui/core';
import { MATRIMONY_STEP_DISTANCE, MEMBER_HEIGHT } from '../const';
import {
  useCalculateMemberGeometry,
  useMemberVirtualRendered,
} from '../hooks/tree.hooks';

function MemberVirtual({
  memberId,
  isPartner = false,
}: {
  memberId: MemberId;
  isPartner?: boolean;
}) {
  const member = useMemberById(memberId);
  const cardRef = useRef(null);

  useMemberVirtualRendered();

  useCalculateMemberGeometry(memberId, cardRef);

  return (
    <Box
      sx={{
        display: 'inline-block',
        minWidth: 125,
        verticalAlign: 'top',
        paddingBottom: MATRIMONY_STEP_DISTANCE,
        ...((isPartner && {
          paddingTop: MATRIMONY_STEP_DISTANCE,
          paddingBottom: 0,
        }) ||
          {}),
      }}
    >
      <Card
        id={memberId}
        ref={cardRef}
        sx={{
          height: MEMBER_HEIGHT,
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          {member.image && (
            <Box sx={{ height: MEMBER_HEIGHT, width: MEMBER_HEIGHT }} />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '8px 16px',
              textAlign: 'left',
            }}
          >
            <Typography variant="subtitle1">{`${member.name}${
              member.lastName ? ` ${member.lastName}` : ''
            }${
              member.maidenName ? ` (${member.maidenName})` : ''
            }`}</Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default React.memo(MemberVirtual);
