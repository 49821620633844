import { Notification, NotificationId } from '../../interfaces';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';
import { RootState } from '../store';
import { fetchFamily } from './family.slice';
import { NotificationType } from '../../enums';

const notificationsAdapter = createEntityAdapter<Notification>();
const createNotification = (data: Omit<Notification, 'id'>): Notification => ({
  ...data,
  id: uuidv1(),
});

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState(),
  reducers: {
    addNotification: {
      reducer: notificationsAdapter.addOne,
      prepare(data: Omit<Notification, 'id'>) {
        return { payload: createNotification(data) };
      },
    },
    removeNotification: notificationsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFamily.rejected, (state, action) => {
      notificationsAdapter.addOne(
        state,
        createNotification({
          type: NotificationType.ERROR,
          message: `Failed to fetch Family data. ${action.error?.message}`,
        }),
      );
    });
  },
});

export const { addNotification, removeNotification } =
  notificationsSlice.actions;

export const { selectAll: selectNotifications } =
  notificationsAdapter.getSelectors<RootState>((state) => state.notifications);

export const notificationsReducer = notificationsSlice.reducer;
