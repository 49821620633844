import { useCallback, useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import { hasCredentialsInStorage } from '../utils/auth.utils';
import {
  selectIsAuthenticated,
  setIsAuthenticated,
} from '../store/slices/app.slice';
import { ACCESS_TOKEN_ALIAS, REFRESH_TOKEN_ALIAS } from '../const';
import { AccessTokens } from '../interfaces/AccessTokens.interface';
import { useNavigate } from '@reach/router';

export function useSetIsAuthenticated() {
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    if (hasCredentialsInStorage()) {
      dispatch(setIsAuthenticated(true));
    }
  }, [dispatch]);
}

export function useLogin() {
  const dispatch = useAppDispatch();
  return useCallback(
    (tokens: AccessTokens) => {
      window.localStorage.setItem(ACCESS_TOKEN_ALIAS, tokens.accessToken);
      window.localStorage.setItem(REFRESH_TOKEN_ALIAS, tokens.refreshToken);
      dispatch(setIsAuthenticated(true));
    },
    [dispatch],
  );
}

export function useLogout() {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    window.localStorage.removeItem(ACCESS_TOKEN_ALIAS);
    window.localStorage.removeItem(REFRESH_TOKEN_ALIAS);
    dispatch(setIsAuthenticated(false));
  }, [dispatch]);
}

export function useIsAuthenticated() {
  return useAppSelector(selectIsAuthenticated);
}

export function useUnauthenticatedOnly(redirectRoute = '/') {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useLayoutEffect(() => {
    if (isAuthenticated) {
      navigate(redirectRoute);
    }
  }, [redirectRoute, navigate, isAuthenticated]);
}
